import { Col, Row } from "react-bootstrap";
import { useDataStore } from "../../store/dataStore";
import CartCard from "../Carts/CartCard";
import NewCartCard from "./RecentCarts/NewCartCard";
import { FaArrowLeft } from "react-icons/fa";
import { CSSProperties, useMemo } from "react";
import { CartStatus } from "../../constants";

const RECENT_CARTS_CONTENT_STYLE: CSSProperties = {
  overflowX: "auto",
  overflowY: "hidden",
};

const RecentCartsContent = () => {
  const recentCarts = useDataStore((state) => state.recentCarts);

  const recentOpenCarts = useMemo(
    () => recentCarts.filter((cart) => cart.status === CartStatus.OPEN),
    [recentCarts]
  );

  if (recentOpenCarts.length > 0) {
    return (
      <Col xs={9}>
        <Row className="mt-8 flex-nowrap" style={RECENT_CARTS_CONTENT_STYLE}>
          {recentOpenCarts.map((cart) => (
            <Col key={cart.id} xs={4}>
              <CartCard height={344} cart={cart} />
            </Col>
          ))}
        </Row>
      </Col>
    );
  }
  return (
    <Col
      xs={9}
      className="d-flex flex-column justify-content-center text-body-secondary text-secondary"
      p
    >
      <h5 className="text-body-secondary text-secondary">
        <p>No tienes cotizaciones abiertas</p>
        <span>
          <FaArrowLeft />
          <span className="m-2">crea una aquí!</span>
        </span>
      </h5>
    </Col>
  );
};

const RecentCarts = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={3}> </Col>
        <Col xs={9}>
          <h4>Cotizaciones Recientes</h4>
        </Col>
      </Row>

      <Row className="mt-8 ">
        <Col xs={3}>
          <NewCartCard />
        </Col>
        <RecentCartsContent />
      </Row>
    </div>
  );
};

export default RecentCarts;
