import { Tabs, Tab, Container } from "react-bootstrap";
import { CartStatus } from "../../constants";
import CartList from "./CartList";
import { useCurrentUserContext } from "../../contexts/currentUserContext";

const CartLists = () => {
  const { isStaff: isAdmin } = useCurrentUserContext();

  return (
    <Container>
      <Tabs defaultActiveKey={CartStatus.OPEN} id="cart-lists" className="mb-3">
        <Tab eventKey={CartStatus.OPEN} title={"Abiertas"}>
          <CartList cartStatus={CartStatus.OPEN} />
        </Tab>
        {isAdmin && (
          <Tab eventKey={CartStatus.OPEN_PRIVATE} title={"Privadas"}>
            <CartList cartStatus={CartStatus.OPEN_PRIVATE} />
          </Tab>
        )}
        {/* <Tab eventKey={CartStatus.PENDING} title={"Processando"}>
          <CartList cartStatus={CartStatus.PENDING} />
        </Tab> */}
        <Tab eventKey={CartStatus.CLOSED} title={"Cerradas"}>
          <CartList cartStatus={CartStatus.CLOSED} />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default CartLists;
