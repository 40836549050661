import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DetCart, EntityType } from "../types";
import { getList, getOne, sessionCart } from "../components/djangoHelpers";
import { CookieKey } from "../constants";
import Cookies from "js-cookie";
import { useCurrentUserContext } from "./currentUserContext";

interface StoreContextInterface {
  activeCart: DetCart | undefined;
  setActiveCart: (cartId: number | undefined) => void;
  reloadActiveCart: () => void;
  loadActiveCart: (cartId: number) => Promise<DetCart | undefined>;
}

const StoreContext = React.createContext<StoreContextInterface | null>(null);

function StoreProvider({ children }: { children: React.ReactNode }) {
  const [activeCart, setActiveCart] = useState<DetCart | undefined>(undefined);
  const { isStaff } = useCurrentUserContext();

  const clearActiveCart = useCallback(() => {
    setActiveCart(undefined);
    Cookies.remove(CookieKey.ActiveCartId);
    if (isStaff) {
      sessionCart(undefined);
    }
  }, [isStaff]);

  const loadActiveCart = useCallback(
    async (cartId: number) => {
      const cartData = await getOne<DetCart>(
        EntityType.Carts,
        true,
        cartId,
        () => {
          clearActiveCart();
        }
      );
      if (isStaff && cartData?.client.id) {
        await sessionCart(cartData?.client.id);
        await getList(EntityType.Products, true, false)
      }

      setActiveCart(cartData);
      return cartData;
    },
    [clearActiveCart, isStaff]
  );

  const reloadActiveCart = useCallback(async () => {
    if (activeCart) {
      loadActiveCart(activeCart.id);
    }
  }, [activeCart, loadActiveCart]);

  const initialLoad = useRef<boolean>(false);

  useEffect(() => {
    if (!initialLoad.current) {
      const cookieActiveCartId = Cookies.get(CookieKey.ActiveCartId);

      if (cookieActiveCartId && !activeCart) {
        loadActiveCart(parseInt(cookieActiveCartId));
      }
    }
    initialLoad.current = true;
  }, [activeCart, loadActiveCart]);

  const setActiveCartHelper = useCallback(
    (newCartId?: number) => {
      if (newCartId) {
        Cookies.set(CookieKey.ActiveCartId, newCartId.toString(), {
          expires: 365,
        });
        loadActiveCart(newCartId);
      } else {
        clearActiveCart();
      }
    },
    [clearActiveCart, loadActiveCart]
  );

  const value = useMemo<StoreContextInterface>(
    () => ({
      activeCart,
      setActiveCart: setActiveCartHelper,
      reloadActiveCart,
      loadActiveCart,
    }),
    [activeCart, setActiveCartHelper, reloadActiveCart, loadActiveCart]
  );

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

export const useStoreContext = () => {
  return useContext(StoreContext) as StoreContextInterface;
};

export default StoreProvider;
