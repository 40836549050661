import { FaPlusCircle } from "react-icons/fa";
import { CartEntry } from "./PaginatedTableRow";
import { Form } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { PaginatedEntityType, patchOne } from "../djangoHelpers";
import {
  CartProductEntry,
  Entity,
  EntityType,
  OnChangeEvent,
} from "../../types";
import { useStoreContext } from "../../contexts/storeContext";

const EntityTypeToEntry: Partial<Record<PaginatedEntityType, EntityType>> = {
  [EntityType.Products]: EntityType.CartProductEntries,
};

const AddToCartButton = ({
  cartEntry,
  entityType,
  postEntryToCart,
  deleteEntryFromCart,
}: {
  cartEntry: CartEntry | undefined;
  entityType: PaginatedEntityType;
  postEntryToCart: () => void;
  deleteEntryFromCart: () => void;
}) => {
  const { reloadActiveCart } = useStoreContext();
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (cartEntry) {
      setQuantity(cartEntry.quantity);
    } else {
      setQuantity(undefined);
    }
  }, [cartEntry]);

  const onChange = useCallback(
    (event: OnChangeEvent) => {
      if (!cartEntry) {
        throw new Error("Cart Entry not defined");
      }

      event.target.focus();
      // Allow only integer input
      const stringValue = event.target.value.replace(/\D/g, "");

      if (stringValue === "") {
        setQuantity(0);
        return;
      }

      const value = parseInt(event.target.value.replace(/\D/g, ""));
      if (isNaN(value)) {
        return;
      }

      setQuantity(Math.max(value, 0));
    },
    [cartEntry]
  );

  const onBlur = useCallback(() => {
    if (!cartEntry) {
      throw new Error("Cart Entry not defined");
    }

    if (quantity === 0) {
      deleteEntryFromCart();
      return;
    }
    const dataToUpdate = {
      quantity,
    } as Partial<Entity>;

    // TODO: HANDLE KIT CART ENTRY EDIT
    const realEntityType = EntityTypeToEntry[entityType];
    if (realEntityType) {
      patchOne(realEntityType, cartEntry.id, dataToUpdate).then(
        (res: CartProductEntry) => {
          setQuantity(res.quantity);
          reloadActiveCart();
        }
      );
    }
  }, [cartEntry, deleteEntryFromCart, entityType, quantity, reloadActiveCart]);

  return (
    <>
      {!cartEntry ? (
        <td className="tableCell-add" onClick={postEntryToCart}>
          <FaPlusCircle />
        </td>
      ) : (
        <td
          className="tableCell-remove"
          // onClick={deleteEntryFromCart}
        >
          <span>
            <Form>
              <Form.Group controlId="integerInput">
                <Form.Control
                  type="number"
                  min="0"
                  step="1"
                  style={{ padding: 0, width: "75px" }}
                  value={quantity || 0}
                  onChange={onChange}
                  onBlur={onBlur}
                  autoFocus={false}
                />
              </Form.Group>
            </Form>
            {/* <FaTimesCircle /> */}
          </span>
        </td>
      )}
    </>
  );
};
export default AddToCartButton;
