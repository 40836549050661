import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { EntityType, KV, NewCart } from "../../types";
import { useUiStore } from "../../store/uiStore";
import { postOne } from "../djangoHelpers";
import { useCurrentUserContext } from "../../contexts/currentUserContext";
import UserInput from "../Editors/Implementations/UserInput";
import { useDataStore } from "../../store/dataStore";
import { useStoreContext } from "../../contexts/storeContext";
import { ENTITY_TYPE_TO_FRONTEND_NAME } from "../../constants";

const CartCreateModal = () => {
  const {
    currentUser,
    isStaff: isAdmin,
    selectedClientId,
  } = useCurrentUserContext();

  const clientId = selectedClientId
    ? selectedClientId
    : currentUser
    ? currentUser.id
    : undefined;

  if (!clientId) {
    throw new Error("no client id");
  }

  const [newCart, setNewCart] = useState<NewCart>({
    name: "",
    comments: "",
    client: clientId,
  });

  const showCartCreateModal = useUiStore((state) => state.showCartCreateModal);
  const dataStoreApi = useDataStore((state) => state.api);
  const uiStoreApi = useUiStore((state) => state.api);
  const { setActiveCart } = useStoreContext();

  // updates state.newCartTemplate every time an input field from the create new product modal is changed
  const upateNewCartField: KV<NewCart> = useCallback(
    (type, value) => {
      setNewCart({ ...newCart, [type]: value });
    },
    [newCart]
  );

  const actionDisabled = useMemo(
    () => newCart.name === "" || newCart.client === -1,
    [newCart.client, newCart.name]
  );

  const saveNewCart = useCallback(() => {
    const cartData = { ...newCart };
    if (newCart.comments === "") {
      cartData.comments = undefined;
    }

    postOne(EntityType.Carts, newCart, (respData) => {
      dataStoreApi.loadRecentCarts();
      setActiveCart(respData.id);
    });
    uiStoreApi.toggleShowCartCreateModal();
  }, [dataStoreApi, newCart, setActiveCart, uiStoreApi]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        saveNewCart();
      }
    },
    [saveNewCart]
  );

  return (
    <Modal
      show={showCartCreateModal}
      onHide={uiStoreApi.toggleShowCartCreateModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Nueva {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formGridAddress1">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            autoFocus={true}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              upateNewCartField("name", e.target.value);
            }}
            placeholder="Nombre..."
          />
          <Form.Label>Anotaciones</Form.Label>
          <Form.Control
            onKeyDown={onKeyDown}
            onChange={(e) => {
              upateNewCartField("comments", e.target.value);
            }}
            placeholder="Anotaciones..."
          />
        </Form.Group>
        {isAdmin && (
          <>
            <Form.Label>Cliente</Form.Label>
            <UserInput
              onChange={(value) => upateNewCartField("client", Number(value))}
              value={undefined}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="submit"
          onClick={uiStoreApi.toggleShowCartCreateModal}
        >
          Cerrar
        </Button>
        <Button variant="dark" onClick={saveNewCart} disabled={actionDisabled}>
          Crear {ENTITY_TYPE_TO_FRONTEND_NAME[EntityType.Carts]}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartCreateModal;
